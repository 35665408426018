import logo from './logo.svg';
import './App.css';
import Contenu from './components/Contenu';
import Connexion from './components/Connection';
import  Home from './components/Home';
import React, { useState, useEffect } from 'react';




interface igame {
  name:string;
  id:number;
  playerNumber:number;
  bvote:boolean;
  round:number;
}

interface iplayer {
  pseudo: string;
  role: string;
  id: string;
  vote: number;
  gameId:number;
  voteList:string[];
  alive:boolean;
}


function App() {

      return (
        <>
        <Contenu></Contenu>
        </>
      );

}

export default App;

