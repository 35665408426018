import './Contenu.css';
import img from '../image_cv.jpg';
import fond from '../p.png';
import { tab } from '@testing-library/user-event/dist/tab';
import { TypeAnimation } from 'react-type-animation';

function Contenu() {

    function test(num:number) {
        let valp:string="";
        let valpp:string="";
        let p = document.getElementById("p"+num);
        let pp = document.getElementById("pp"+num);
        if(p && pp) { 
            valp=(p as HTMLParagraphElement).style.display;
            valpp=(pp as HTMLParagraphElement).innerHTML;
            if (valp=="none") {
                (p as HTMLParagraphElement).style.display="block";
                (pp as HTMLParagraphElement).innerHTML=valpp.substring(0,valpp.length-3);
            } else {
                (p as HTMLParagraphElement).style.display="none";
                (pp as HTMLParagraphElement).innerHTML=valpp+" ...";
            }
        }
    }



    return(
        <>
           <div className="timeline-area">
            <div style={{textAlign:"center",background: "rgba(255,255,255,0.5)"}}>
                <img id="avatar" className='image_kdo' src={img}></img>     

                <div className="timelines">
                    <h6>25 ans, véhiculé</h6>
                </div>
                <br/>
                <div className="timelines">
                    <h6><a href="mailto:thomasamand98@gmail.com">thomasamand98@gmail.com</a></h6>
                </div>
                <br/>
                <div className="timelines">
                    <h6><a href="tel:+33671222139">+33 6 71 22 21 39</a></h6>
                </div>
                <br/>
            </div>

           

            <div className="container">
                <br/>
                <br/>
                <div className="timelines">
                    <h2>Expériences professionnelles</h2>
                </div>
                <div className="all-timelines">


                <div className="single-timeline d-flex-2" onClick={() => test(1)} >
                    <div className="timeline-blank"></div>
                    <div className="timeline-text d-flex-2">
                    <span>
                        <h6>Novembre 2021 - Aujourd'hui (+ 2 ans)</h6> — Ace Software
                        <br/>
                        <p id="pp1">Développeur (CDI) ...</p>
                        <p id="p1" style={{display:"none"}}>Test texte long tres long tellement long de la mort (CDI) Test texte long tres long tellement long de la mort (CDI)Test texte long tres long tellement long de la mort (CDI) Test texte long tres long tellement long de la mort (CDI) Test texte long tres long tellement long de la mort (CDI) Test texte long tres long tellement long de la mort (CDI) Test texte long tres long tellement long de la mort (CDI) Test texte long tres long tellement long de la mort (CDI) v Test texte long tres long tellement long de la mort (CDI)</p>
                    </span>
                    <div className="t-square"></div>
                    </div>
                    <br/>

                    
                </div>

                <div className="single-timeline d-flex-2" onClick={() => test(2)}>
                    <div className="timeline-blank"></div>
                    <div className="timeline-text d-flex-2">
                    <span>
                        <h6>Janvier 2020 - Juillet 2021 (1 an)</h6> — T-plus
                        <br/>
                        <p id="pp2">Interimaire (CDI) ...</p>
                        <p id="p2" style={{display:"none"}}>Test texte long tres long tellement long de la mort (CDI)</p>
                    </span>
                    <div className="t-square"></div>
                    </div>
                </div>
                
                <div className="single-timeline d-flex-2" onClick={() => test(3)}>
                    <div className="timeline-blank"></div>
                    <div className="timeline-text d-flex-2">
                    <span>
                        <h6>Juin - Decembre 2019 (7 mois)</h6> — Club Med
                        <br/>
                        <p id="pp3">Chef de rang (CDD) ...</p>
                        <p id="p3" style={{display:"none"}}>Test texte long tres long tellement long de la mort (CDI)</p>
                    </span>
                    <div className="t-square"></div>
                    </div>
                </div>
                
                <div className="single-timeline d-flex-2" onClick={() => test(4)}>
                    <div className="timeline-blank"></div>
                    <div className="timeline-text d-flex-2">
                    <span>
                        <h6>Juin 2018 - Mars (10 mois)</h6> — La marmite enchantée
                        <br/>
                        <p id="pp4">Equipier Polyvalent (CDI) ...</p>
                        <p id="p4" style={{display:"none"}}>Test texte long tres long tellement long de la mort (CDI)</p>
                    </span>
                    <div className="t-square"></div>
                    </div>
                </div>
                
                <div className="single-timeline d-flex-2" onClick={() => test(5)}>
                    <div className="timeline-blank"></div>
                    <div className="timeline-text d-flex-2">
                    <span>
                        <h6>2016 - 2017 (1 an)</h6> Faculté des sciences, Nice
                        <br/>
                        <p id="pp5">Licence mathematiques et informatiques ...</p>
                        <p id="p5" style={{display:"none"}}>Test texte long tres long tellement long de la mort (CDI)</p>
                    </span>
                    <div className="t-square"></div>
                    </div>
                </div>

                <div className="single-timeline d-flex-2" onClick={() => test(6)}>
                    <div className="timeline-blank"></div>
                    <div className="timeline-text d-flex-2">
                    <span>
                        <h6>2016</h6> — Baccalauréat scientifique
                        <br/>
                        <p id="pp6">+ Option sport (cyclisme, ski, escalade) ...</p>
                        <p id="p6" style={{display:"none"}}>Test texte long tres long tellement long de la mort (CDI)</p>
                    </span>
                    <div className="t-square"></div>
                    </div>
                </div>

                </div>

                <div className="container">
                <br/>
                <br/>
                <div className="timelines">
                    <h2>Langages</h2>
                </div>



                        <table  style={{width:"100%",textAlign:"center"}}>
                           
                            <tr>
                                <td style={{width:"200px"}}><TypeAnimation 
                                    sequence={[
                                        'PHP',
                                        1000,
                                        'Python',
                                        1000,
                                        'React (JS)',
                                        1000, 
                                        'Typescript (JS)',
                                        1000,
                                        'WLangage (Windev)',
                                        1000,
                                        'HTML/CSS',
                                        1000,
                                    ]}
                                    wrapper="span"
                                    speed={50}
                                    style={{ fontSize: '2em', display: 'inline-block' }}
                                    repeat={Infinity}
                                    />
                                </td>
                            </tr>
                           

                        </table>
      

            </div>


            </div>
           </div>
        </>
    )
} 




export default Contenu;


